
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.badge {
  z-index: 2;
  color: $color-accent-fresh;

  &.insideCard {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &.sale {
    background-color: $color-plush-primary;
  }

  &.bestseller {
    background-color: $color-explorer-primary;
  }

  &.limited {
    background-color: $color-fandango-primary;
  }

  &.lastChance {
    background-color: $color-plum-primary;
  }

  &.new {
    background-color: $color-mantis-primary;
  }

  &.freeShipping {
    background-color: $color-indigo-primary;
  }

  &.blackFriday {
    background-color: $color-accent-italian;
  }
}
